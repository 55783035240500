import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Picblock from '../components/picblock';
// import * as styles from "../components/index.module.css"

export default function SDAbout({ data }) {
  const picdata = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="About" />
      <div>
        { picdata.map((data) => (
          <Picblock data={data} key={data.id} />
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
query aboutpage {
    allMarkdownRemark(
      sort: {fields: frontmatter___sortorder, order: ASC}
      filter: {frontmatter: {page: {eq: "about"}}}
    ) {
      nodes {
        id
        frontmatter {
          page
          pictitle
          sortorder
          title
          imageside
          imageurl {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
        html
      }
    }
  }
  
`;

// TODO:  You can publish a test website with surge.sh.
